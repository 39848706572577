import { defineComponent, ref, inject, computed } from "@vue/composition-api";
import ButtonWithIcon from "@/components/game/pages/yama/shared/buttonWithIcon";
import { state, getters } from "../store";
import { useTimeTracker } from "../composables/useTimeTracker";
import { STATUS } from "../composables/useGameState"

export default defineComponent({
  components: {
    ButtonWithIcon
  },
  props: {
    injectKey: {
      type: [String, Symbol],
      required: true,
    },
    isEnd: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, context) {
    const hasDone = computed(() => state.game.status === STATUS.PENDING)
    const $stage = inject(props.injectKey)

    const userList = getters.onlineUserList
    const current = ref(state.game.questions[0]?.id || "")
    const currentOptions = computed(() => {
      return state.game.questions.find(q => String(q.id) === String(current.value))
    })

    const currentQuestionHasDone = computed(() => {
      return !!userOngoingAnswer.value.answers.find(a => a.question_id === String(current.value))?.answer
    })

    const userOngoingAnswer = getters.userOngoingAnswer
    const questionHasDone = computed(() => userOngoingAnswer.value.answers.every(a => !!a.answer))

    const changeQuestion = id => current.value = String(id)

    const whoChooseCurrentQuestion = computed(() => {
      return state.game.ongoing_answers.reduce((a, item) => {
        const answer = item.answers.find(ans => String(ans.question_id) === String(current.value))
        if (!answer || !answer.answer) return a

        if (a[answer.answer]) a[answer.answer].push(item.line_id)
        else a[answer.answer] = [item.line_id]

        return a
      }, {})
    })

    const getMember = line_id => state.game.members.find(user => user.line_id === line_id)
    const isActive = option => whoChooseCurrentQuestion.value[option] && whoChooseCurrentQuestion.value[option].includes(state.lineId)

    const time_left_by_second = computed(() => state.game.time_left_by_second)
    const {isoTime: time} = useTimeTracker(time_left_by_second)

    const finishAnswer = () => {
      questionHasDone.value && context.emit('finish-answer')
    }

    return {
      hasDone,
      $stage,
      userList,
      currentOptions,
      current,
      changeQuestion,
      whoChooseCurrentQuestion,
      getMember,
      isActive,
      time,
      userOngoingAnswer,
      questionHasDone,
      finishAnswer,
      currentQuestionHasDone
    }
  },
});