<template>
  <button class="Button__withIcon option" @click="$emit('choose-option')">
    <slot></slot>
    <img :src="activeIcon" class="option__icon-active" aria-hidden="true" draggable="false">
  </button>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: "ButtonWithIcon",
  props: {
    activeIcon: {
      type: String,
      required: true,
    }
  }
})
</script>

<style lang="scss">
.Button__withIcon {
  position: relative;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 1.95px;
  color: #333333;
  .option__icon-active {
    display: none;
    position: absolute;
    width: 29px;
    height: 29px;
    top: -6px;
    right: -15px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.active,
  &:not(.hasDone):hover {
    .option__icon-active {
      display: block;
    }
  }
}
</style>
